import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from "classnames";
import calculateSpacing from "../../shared/functions/calculateSpacing";

const styles = (theme: any) => ({
    containerFix: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        overflow: "hidden",
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(1)
    },
    sectionHeader: {
        '&::after': {
            content: `''`,
            position: 'absolute',
            left: 'calc(50% - 50px);',
            width: '100px',
            border: `2px solid ${theme.palette.primary.main}`,
            bottom: '5px'
        }
    },
    itemTitle: {
        color: theme.palette.secondary.main,
    }
});

function WorkInProgress(props: any) {
    const { width, classes } = props;
    return (
        <div className={classNames(classes.containerFix, "container")}>
            <Grid
                container
                spacing={calculateSpacing(width)}
                className={classes.gridContainer}
                style={{ textAlign: 'center' }}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12} style={{ position: 'relative' }}>
                    <Typography className={classes.sectionHeader}
                        variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                        RECENTLY ADDED
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <Typography
                                className={classes.itemTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                English - Nepalbhasa Dictionary
                            </Typography>
                        </Grid>
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <Typography className={classes.itemTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                Nepali - Nepalbhasa Dictionary
                            </Typography>
                        </Grid>
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <Typography className={classes.itemTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                Dolakha and Bhaktapur Dialect
                            </Typography>
                        </Grid>
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <Typography className={classes.itemTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                Nepalbhasa Video Turorials
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default withWidth()(withStyles(styles, { withTheme: true })(WorkInProgress));
