import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CheckIcon from "@material-ui/icons/Check";
import AppService from '../../service/app-service';

export interface IWeekWordCardProp {
  highlighted: boolean,
  classes: any,
  theme: any
}
export interface IWeekWordCardState {
  weekWords: string[],
  highlighted: boolean
}

const styles = (theme: any) => ({
  card: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    border: `3px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2
  },
  cardHightlighted: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    border: `3px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1)
    }
  },
  title: {
    color: theme.palette.primary.main
  }
});

class WeekWordCard extends React.PureComponent<IWeekWordCardProp, IWeekWordCardState>{
  constructor(props: IWeekWordCardProp) {
    super(props);
    this.state = {
      weekWords: [],
      highlighted: true
    }

  }
  componentDidMount() {
    if (this.state.weekWords.length === 0) {
      AppService.getWordsOfWeek().then((response: any) => {
        this.setState({
          weekWords: response.words,
          highlighted: true
        })
      });
    }
  }

  render() {
    return (<div className={this.props.highlighted ? this.props.classes.cardHightlighted : this.props.classes.card}>
      <Box mb={2}>
        <Typography
          variant={this.props.highlighted ? "h5" : "h6"}
          className={this.props.highlighted ? "text-white" : this.props.classes.title}
        >
          वंगु वाःया खँग्वः
          </Typography>
      </Box>
      <Box mb={2}>
        <Typography
          variant={this.props.highlighted ? "h6" : "h6"}
          className={this.props.highlighted ? "text-white" : ""}
        >
          Last week's words
          </Typography>
      </Box>
      {this.state.weekWords.map((word: string, index: number) => (
        <Box display="flex" alignItems="center" mb={1} key={index}>
          <CheckIcon
            style={{
              color: this.props.highlighted
                ? this.props.theme.palette.common.white
                : this.props.theme.palette.primary.dark
            }}
          />
          <Box ml={1}>
            <Link color="inherit" className={this.props.highlighted ? "text-white" : ""}
              variant={this.props.highlighted ? "h6" : "body1"} href={"/dictionary/nb/" + word}>{word}</Link>
          </Box>
        </Box>
      ))}
    </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(WeekWordCard);
