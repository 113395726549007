import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import RandomWordCard from '../home/random-word';
//import IVideo from from '../models/word-meaning';

import { withWidth, withStyles, isWidthUp } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import General from '../general';

const styles = (theme: any) => ({
  container: {
    alignItems: 'center'
  },
  title: {
    lineHeight: 2,
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
  randomWordWrapper: {
    marginTop: theme.spacing(8),
  },
  
});

function Videos(props: any) {
  const { classes, width } = props;

  useEffect(() => {
    props.selectVideos();
  });

  const mainContainer = () => {

    const iframeStyle = {
      aspectRatio: '16 / 9',
      width: isWidthUp('lg', width) ? '90%' : '100%',
      margin: '10px 0', 
    };

    return (<Fragment>
      <Box pt={3} pr={3} pl={3} pb={2} className='row'>
        <Typography variant={isWidthUp("lg", width) ? "h4" : "h5"}>भिडियो ट्यूटोरियल (Video Turorials)</Typography>
      </Box>
      
      <Typography variant="h6">
                <span className={classes.title}>Introduction</span>
      </Typography>
      <Typography>
                Nepalbhasa App Introduction
      </Typography>

      <iframe
         style={iframeStyle}
        src="https://www.youtube.com/embed/bhPq-zUGPx0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

    </Fragment>);
  };

  const topContainer = () => {
    return (
      <div className={classes.randomWordWrapper}>
        <RandomWordCard highlighted={false} />
      </div>
    )};
  
  return (
    <General
      mainContent={mainContainer()}
      sideContentTop={topContainer()}
    />
  );
}

Videos.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(Videos));
