import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import classNames from 'classnames';

const styles = (theme: any) => ({
  specialChars: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: theme.spacing(1),
    marginBottom:theme.spacing(1),
    padding: '3px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '3px',
      padding: '2px'
    },
  },
  activeSpecialChars: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: theme.spacing(1),
    marginBottom:theme.spacing(1),
    padding: '3px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      marginRight: '3px',
      padding: '2px'
    },
  },
  specialCharsContainer: {
    display: 'flex',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2.5px',
      paddingRight: '2.5px',
      flexWrap: 'wrap',
    }
  },
  noDecoration: {
    color: theme.palette.common.black,
    textDecoration: 'none !important',
    marginBottom:theme.spacing(2)
  },
  showFocus: {
    '&:focus span': {
      color: theme.palette.secondary.dark,
    },
  },
});
function IndexedList(props: any) {
const {
    data,
    classes,
    selectedLetter,
    route,
  } = props;
  return (
    <Grid
    item
    xs={12}
    sm={12}
    lg={12}>
    <div className={classes.specialCharsContainer}>
      {data.map((special: string, index: number) => (
       <Link
        to={`/${route}/${special.toLocaleLowerCase()}`}
        key={index}
        className={classNames(
          classes.noDecoration,
          classes.showFocus
        )}
      >
        <span className={selectedLetter === special.toLowerCase()?classes.activeSpecialChars:classes.specialChars} >{special}</span>
      </Link>
      ))}
    </div>
  </Grid>
  );
};
export default withWidth()(
  withStyles(styles, { withTheme: true })(IndexedList)
);
