import React, { Fragment, useEffect } from 'react';
import FeatureSection from './feature-section';
import HeaderSection from './header-section';
import CounterSection from './counter-section';
import WorkInProgress from './work-in-progress';

function Home(props: any) {
  const { selectHome } = props;

  useEffect(() => {
    selectHome();
  }, [selectHome]);

  return (
    <Fragment>
      <HeaderSection />
      <FeatureSection />
      <CounterSection />
      <WorkInProgress />
    </Fragment>
  );
}

export default Home;
