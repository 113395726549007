import AppService from './app-service';
import configData from '../config.json';
import wordofday from '../images/nepalbhasa-word-of-the-day.jpg';
import {
  IWordMeaning,
  ISearchResult,
  IWordOfTheDay,
  ISearchResultInEnglish,
  IWordMeaningFromEnglish,
  ISearchResultInNepali,
  IWordMeaningFromNepali,
  
} from '../models/word-meaning';

export default class WordService {
  public static async GetWordMeaning(word: string): Promise<ISearchResult> {
    let result: ISearchResult = {
      error: '',
      meanings: [],
      word: '',
      transliterations: { latn:'', deva: '', newa:''},
    };
    try {
      const response = await AppService.searchGet(word);
      let arr: IWordMeaning[] = [];

      response.meanings.forEach((meaning: any) => {
        let wordmeaning: IWordMeaning = {
          formerword: meaning['formerword'],
          pos: meaning['pos'],
          meaning: meaning['meaning'],
          dialect: meaning['dialect'],
          prefsuf: meaning['prefsuf'],
          compword: meaning['compword'],
          origin: meaning['origin'],
          reference: meaning['reference'],
          relatedwords: meaning['relatedwords'],
        };
        arr.push(wordmeaning);
      });

      result.error = response['errors'].length ? response.errors[0] : '';
      result.meanings = arr;
      result.word = response['word'];
      result.transliterations = response['transliterations'];

      if (
        response['audio'] !== null &&
        response['audio'].file !== undefined &&
        response['audio'].file.length > 0
      ) {
        result.audioUrl =
          configData.API_URL +
          '/' +
          response['audio'].directory +
          '/' +
          response['audio'].file;
      }
      if (
        response['image'] != null &&
        response['image'].file !== undefined &&
        response['image'].file.length > 0
      ) {
        result.imageUrl =
          configData.API_URL +
          '/' +
          response['image'].directory +
          '/w600h400b1sh1/' +
          response['image'].file;
        result.magnifiedImageUrl =
          configData.API_URL +
          '/' +
          response['image'].directory +
          '/w1200h800b1sh1/' +
          response['image'].file;
      }
      return result;
    } catch (err) {
      result.error = configData.ERROR_MSG
      return  result;
    }
  }

  public static async GetWordMeaningFromEnglish(word: string): Promise<any> {
    let result: ISearchResultInEnglish = {
      errors: [],
      meanings: [],
      word: word,
    };
    let arr: IWordMeaningFromEnglish[] = [];

    try {
    const response =  await AppService.searchGet(word, 'en');
    response.meanings.forEach((meaning: any) => {
      let wordmeaning: IWordMeaningFromEnglish = {
        pos: meaning['pos'],
        meaning_en: meaning['meaning_en'],
        meaning_nb: meaning['meaning_nb'],
        meaning_np: meaning['meaning_np'],
        dialect: meaning['dialect'],
        reference: meaning['reference'],
        relatedwords: meaning['relatedwords'],
        transliterations: meaning['transliterations'],
        audioUrl:(meaning['audio'] !== null && meaning['audio'].file !== undefined && meaning['audio'].file.length > 0) ? `${configData.API_URL}/${meaning['audio'].directory}/${meaning['audio'].file}` :'',
        imageUrl: '',
        magnifiedImageUrl:''
      };
      if (
        meaning['image'] != null &&
        meaning['image'].file !== undefined &&
        meaning['image'].file.length > 0
      ) {
        wordmeaning.imageUrl =
          configData.API_URL +
          '/' +
          meaning['image'].directory +
          '/w400h250b1sh1/' +
          meaning['image'].file;
        wordmeaning.magnifiedImageUrl =
          configData.API_URL +
          '/' +
          meaning['image'].directory +
          '/w1200h800b1sh1/' +
          meaning['image'].file;
      }
      arr.push(wordmeaning);
    });

      result.errors = response['errors'].length ? response.errors[0] : '';
      result.meanings = arr;
      result.word = response['word'];
      return result;
    } catch (err) {
      result.errors = [configData.ERROR_MSG]
      return  result;
    }
  }

  public static async GetWordMeaningFromNepali(word: string): Promise<any> {
    let result: ISearchResultInNepali = {
      errors: [],
      meanings: [],
      word: word,
      retryword: '',
    };
    let arr: IWordMeaningFromNepali[] = [];

    try {
    const response =  await AppService.searchGet(word, 'np');
    response.meanings.forEach((meaning: any) => {
      let wordmeaning: IWordMeaningFromNepali = {
        pos: meaning['pos'],
        meaning_np: meaning['meaning_np'],
        meaning_nb: meaning['meaning_nb'],
        meaning_en: meaning['meaning_en'],
        dialect: meaning['dialect'],
        reference: meaning['reference'],
        relatedwords: meaning['relatedwords'],
        transliterations: meaning['transliterations'],
        audioUrl:(meaning['audio'] !== null && meaning['audio'].file !== undefined && meaning['audio'].file.length > 0) ? `${configData.API_URL}/${meaning['audio'].directory}/${meaning['audio'].file}` :'',
        imageUrl: '',
        magnifiedImageUrl:''
      };
      if (
        meaning['image'] != null &&
        meaning['image'].file !== undefined &&
        meaning['image'].file.length > 0
      ) {
        wordmeaning.imageUrl =
          configData.API_URL +
          '/' +
          meaning['image'].directory +
          '/w400h250b1sh1/' +
          meaning['image'].file;
        wordmeaning.magnifiedImageUrl =
          configData.API_URL +
          '/' +
          meaning['image'].directory +
          '/w1200h800b1sh1/' +
          meaning['image'].file;
      }
      arr.push(wordmeaning);
    });

      result.errors = response['errors'].length ? response.errors[0] : '';
      result.meanings = arr;
      result.word = response['word'];
      result.word = response['retryword'];
      return result;
    } catch (err) {
      result.errors = [configData.ERROR_MSG]
      return  result;
    }
  }

  public static async GetWordOfTheDay(): Promise<any> {
    try {
      const response = await AppService.getWordOfTheDay();
      let result: IWordOfTheDay = {
        word: response['word'],
        latn: response['transliterations'].latn,
        newa: response['transliterations'].newa,
        meanings: response['meanings'],
        error: response.errors[0],
      };

      if (response['image'] != null && response['image'].file.length > 0) {
        result.imageUrl =
          configData.API_URL +
          '/' +
          response['image'].directory +
          '/w540h300b1sh1/' +
          response['image'].file;
      } else {
        // default image
        result.imageUrl = wordofday;
      }
      if (response['audio'] !== null && response['audio'].file.length > 0) {
        result.audioUrl =
          configData.API_URL +
          '/' +
          response['audio'].directory +
          '/' +
          response['audio'].file;
      }
      return result;
    } catch (err) {
      console.log(err);
    }
  }
}
