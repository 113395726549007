import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

interface IBreadCrumb {
  label: string;
  url: string;
}

function BreadCrumb({ data }: any) {
  //console.log(data, 'data');
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {(data || []).map(({ label, url }: IBreadCrumb, i: number) => {
        if (i === data.length - 1)
          return (
            <Typography
              key={i}
              color="textPrimary"
              style={{ textTransform: 'capitalize' }}
            >
              {label}
            </Typography>
          );
        return (
          <Link
            key={label}
            color="inherit"
            href={`${url}`}
            style={{ textTransform: 'capitalize' }}
          >
            {label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

BreadCrumb.propTypes = {
  data: PropTypes.array.isRequired,
};

export default BreadCrumb;
