import React, { Component, Fragment } from 'react';
import AppService from '../../service/app-service';
import { IProverbsResult, IProverb } from '../../models/word-meaning';
import PaginationLink from '../../shared/components/pagination-link';
import RandomWordCard from '../home/random-word';
import General from '../general';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme: any) => ({
  randomWordWrapper: {
    marginTop: theme.spacing(8),
  },
  link: {
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  noDecoration: {
    textDecoration: 'none !important',
  },
  showFocus: {
    '&:focus span': {
      color: theme.palette.secondary.dark,
    },
  },
  title: {
    lineHeight: 2,
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
  subTitle: {
    fontWeight: 550,
  },
  leftSection: {
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(2),
  }
});

export interface IProverbsState {
  proverbs: IProverb[];
  countPerPage: number;
  totalCount: number;
  page: number;
}

class Proverbs extends Component<any, IProverbsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      proverbs: [],
      countPerPage: 20,
      totalCount: 0,
      page:
        this.props.match.params.page === undefined
          ? 1
          : this.props.match.params.page,
    };
  }
  componentDidMount() {
    this.props.selectProverbs();
    if (this.state.proverbs.length === 0) {
      AppService.getProverbs(this.state.page, this.state.countPerPage).then(
        (response: IProverbsResult) => {
          this.setState({
            proverbs: response.proverbs,
            totalCount: Math.ceil(
              response.totalproverbs / this.state.countPerPage
            ),
          });
        }
      );
    }
  }
  private onPaginationChange = (event: any, value: number): void => {
    this.props.selectProverbs();
    let page: number = value === undefined ? 1 : value;
    AppService.getProverbs(page, this.state.countPerPage).then(
      (response: IProverbsResult) => {
        this.setState({
          proverbs: response.proverbs,
          page: page,
        });
      }
    );
  };

  mainContainer = () => {
    const { classes, width } = this.props;
    return (<Fragment>
      <Box pt={3} pr={3} pl={3} pb={2} className='row'>
        <Typography variant={isWidthUp("lg", width) ? "h4" : "h5"}>
          नेवाः धापूखँ (Newah Proverbs)
            </Typography>
      </Box>
      <Box p={3} className='row'>
        <Grid container spacing={1}>
          {this.state.proverbs.map((proverb: IProverb, index: number) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <Typography variant="h6">
                <span className={classes.title}>{proverb.proverb}</span>
              </Typography>
              <Typography>
                <span className={classes.subTitle}>भावार्थ: </span>
                {proverb.meaning}
              </Typography>
              <Typography>
                <span className={classes.subTitle}>छ्यला: </span>
                {proverb.example}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={3} className='row'>
        Source: Malla (2000)
      </Box>
      <Box p={3} className='row'>
        <PaginationLink
          routeUrl={'proverbs'}
          count={this.state.totalCount}
          page={Number(this.state.page)}
          onPaginationChange={this.onPaginationChange}
        />
      </Box>
    </Fragment>
    );
  };

  topContainer = () => {
    const { classes } = this.props;
    return (
      <div className={classes.randomWordWrapper}>
        <RandomWordCard highlighted={false} />
      </div>
    );
  };

  render() {
    return (
      <General
        mainContent={this.mainContainer()}
        sideContentTop={this.topContainer()}
      />
    );
  }
}
export default withWidth()(withStyles(styles, { withTheme: true })(Proverbs));
