import React, { useEffect, useState } from "react";
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import withWidth from '@material-ui/core/withWidth';
import Typography from "@material-ui/core/Typography";
import AppService from "../../service/app-service";

const styles = (theme: any) => ({
    card: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginTop: theme.spacing(2),
      border: `3px solid ${theme.palette.primary.dark}`,
      borderRadius: theme.shape.borderRadius * 2
    },
    cardHightlighted: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      border: `3px solid ${theme.palette.primary.dark}`,
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2)
      }
    },
    title: {
      color: theme.palette.primary.main
    }
  });

  export interface ITodaysInfo{
    gc: any;
    ns: any;
    solar: any;
    dayofweek: any;
    hour: any;
    minute:any;
    second:any;
  }

  function TodaysInfo(props: any) {
    const { highlighted, classes } = props;

    const [todaysInfo, setTodaysInfo] = useState<ITodaysInfo>();

    useEffect(() => {
        AppService.getTodaysDate().then((response: any)=>{
            setTodaysInfo(response);
        })  
      }, [props])
      
    return (<div className={highlighted ? classes.cardHightlighted : classes.card}>
        <Box mb={2}>
          <Typography
            variant={highlighted ? "h5" : "h6"}
            className={highlighted ? "text-white" : classes.title}
          > थौंया न्हिल्याः </Typography>
        </Box>
        <Box mb={2}>
          <Typography
            variant={highlighted ? "h6" : "h6"}
            className={highlighted ? "text-white" : ""}
          >
          Today's Date - NST
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography
            variant='body1'
            className={highlighted ? "text-white" : ""}
          > {todaysInfo?.gc.month.latn} {todaysInfo?.gc.day.latn}, {todaysInfo?.gc.year.latn}, {todaysInfo?.dayofweek.latn}</Typography>
        </Box>
        <Box mb={2}>
          <Typography
            variant='body1'
            className={highlighted ? "text-white" : ""}
          >
            {todaysInfo?.hour.latn}:{todaysInfo?.minute.latn}:{todaysInfo?.second.latn}
            </Typography>
        </Box>
        <Box mb={2}>
          <Typography
            variant='body1'
            className={highlighted ? "text-white" : ""}
          >
            {todaysInfo?.ns.month.deva} {todaysInfo?.ns.day.deva}, {todaysInfo?.ns.year.deva},  {todaysInfo?.dayofweek.deva}
            </Typography>
        </Box>
        <Box mb={2}>
          <Typography
            variant='body1'
            className={highlighted ? "text-white" : ""}
          >
            {todaysInfo?.hour.deva}:{todaysInfo?.minute.deva}:{todaysInfo?.second.deva}
            </Typography>
        </Box>
      </div>
      );
  }
  export default withWidth()(withStyles(styles, { withTheme: true })(TodaysInfo));
  