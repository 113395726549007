import React, { useState, useEffect } from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    popup: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      textAlign: 'center',
      boxShadow: theme.shadows[5],
      position: 'relative',
      maxWidth: '80%',
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    downloadLink: {
      display: 'block',
      marginTop: theme.spacing(2),
      textDecoration: 'none',
    },
  });

interface Props {
  classes: {
    container: string;
    popup: string;
    closeButton: string;
    downloadLink: string;
  };
}

const DownloadAppPopup: React.FC<Props> = ({ classes }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isPopupClosed = localStorage.getItem('popupClosed');
    if (!isPopupClosed && isMobileDevice()) {
      setIsVisible(true);
    }
  }, []);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('popupClosed', 'true');
  };

  const getAppLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'https://play.google.com/store/apps/details?id=com.dabuli.nepalbhasa';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'https://apps.apple.com/app/nepalbhasa/id6451118187';
    }
    return '#';
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.popup}>
        <button className={classes.closeButton} onClick={handleClose}>
          &times;
        </button>
        <p>Download our app for a better experience!</p>
        <a href={getAppLink()} className={classes.downloadLink}>
          <Button variant="contained" color="primary">
            Download App
          </Button>
        </a>
      </div>
    </div>
  );
};

export default withStyles(styles)(DownloadAppPopup);
