import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import RandomWordCard from './random-word';
import classNames from 'classnames';
import calculateSpacing from '../../shared/functions/calculateSpacing';
import WeekWordCard from './week-word';
import TodaysInfo from './todays-info';
import Hidden from '@material-ui/core/Hidden';

const styles = (theme: any) => ({
  wrapper: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    }
  },
  containerFix: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
  },
  cardWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 340,
    },
  },
  cardWrapperHighlighted: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 360,
    },
  },
});

function FeatureSection(props: any) {
  const { width, classes } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classNames('container-fluid', classes.containerFix)}>
        <Grid
          container
          spacing={calculateSpacing(width)}
          className={classes.gridContainer}
        >
          <Grid
            item
            xs={12}
            sm={6}
            lg={3}
            className={classes.cardWrapperHighlighted}
            data-aos="zoom-in-up"
          >
            <TodaysInfo highlighted={false} />
          </Grid>
          <Grid
            item
            className={classes.cardWrapper}
            xs={12}
            sm={6}
            lg={3}
            data-aos="zoom-in-up"
            data-aos-delay="200"
          >
            {' '}
            <WeekWordCard highlighted={true} />
          </Grid>

          <Hidden mdDown><Grid
            item
            className={classes.cardWrapper}
            xs={12}
            sm={6}
            lg={3}
            data-aos="zoom-in-up"
            data-aos-delay={isWidthUp('md', width) ? '400' : '0'}
          >
            <RandomWordCard highlighted={false} />
          </Grid>
            <Grid
              item
              className={classes.cardWrapper}
              xs={12}
              sm={6}
              lg={3}
              data-aos="zoom-in-up"
              data-aos-delay={isWidthUp('md', width) ? '600' : '200'}
            >
              <RandomWordCard highlighted={false} />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </div>
  );
}

FeatureSection.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(
  withStyles(styles, { withTheme: true })(FeatureSection)
);
