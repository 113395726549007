import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme: any) => ({
  card: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2,
    cursor: 'pointer',
  },
});

export interface IQuizDetailProps {
  id?: any;
  header?: any;
  detail?: any;
}

export interface IQuizCardProps {
  detail: IQuizDetailProps;
  onCardClick: any;
  classes: any;
}

function QuizCard(props: IQuizCardProps) {
  const { detail, onCardClick, classes } = props;
  return (
    <div
      className={classes.card}
      role="presentation"
      onClick={() => onCardClick(detail)}
    >
      <Box mb={2}>
        <Typography variant="h6" className={classes.title}>
          {detail.header}
        </Typography>
        {detail.detail}
      </Box>
    </div>
  );
}

QuizCard.propTypes = {
  detail: PropTypes.object.isRequired,
  onCardClick: PropTypes.func.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(QuizCard));
