import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = (theme: any) => ({
  card: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2,
  },
  navigationContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  message: {
    fontSize: '1.275rem',
    fontWeight: 'bold' as 'bold',
  },
  button: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ebc7d1',
    },
  },
});

const Congratulation = (props: any) => {
  const { classes, onBackClick, onLevelClick } = props;
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={6}>
          <Paper style={{ padding: '3rem' }}>
            {/* <p>Congratulation! </p> */}
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              className={classes.title}
            >
              Congratulation!!!
            </Typography>
            <p>
              <span role="img" aria-label="Panda">
                🎉🎉🎉
              </span>
            </p>
            <p className={classes.message}>
              You have completed all the quizes!!!{' '}
            </p>

            <div className={classes.navigationContainer}>
              <div className={classes.button} onClick={onBackClick}>
                Back
              </div>
              <div className={classes.button} onClick={onLevelClick}>
                Go To Level
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Congratulation);
