import React, { Fragment } from 'react';
import WordService from '../../service/word-service';
import { IWordMeaning } from '../../models/word-meaning';
import Typography from '@material-ui/core/Typography';
import speaker from '../../images/speaker_on.jpg';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { isWidthUp } from '@material-ui/core/withWidth';
import Link from '@material-ui/core/Link';
export interface IWordDayComponentState {
  word: string;
  latn: string;
  newa: string;
  meanings: IWordMeaning[];
  error: string;
  audioUrl?: string;
  imageUrl?: string;
}

class WordDayComponent extends React.PureComponent<
  any,
  IWordDayComponentState
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      word: '',
      latn: '',
      newa: '',
      meanings: [],
      error: '',
    };
  }

  private playAudio() {
    const audio = new Audio(this.state.audioUrl);
    audio.play();
  }

  componentDidMount() {
    if (this.state.meanings.length === 0) {
      WordService.GetWordOfTheDay().then((response: any) => {
        this.setState({
          word: response.word,
          latn: response.latn,
          newa: response.newa,
          meanings: response.meanings,
          error: response.error,
          audioUrl: response.audioUrl,
          imageUrl: response.imageUrl,
        });
      });
    }
  }

  render() {
    const { classes, width } = this.props;
    return (
      <Box justifyContent="space-between" className="row">
        <Grid item xs={12} md={6}>
          <img
            src={this.state.imageUrl}
            className={classes.image}
            alt="Nepalbhasa Word of the Day"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box mb={4} className={classes.containerWord}>
              <Typography variant={isWidthUp('lg', width) ? 'h4' : 'h5'}>
                थौंया खँग्वः (Word of the day)
              </Typography>
              <Fragment>
                {this.state.meanings.length === 0 && this.state.error && (
                  <Typography className={'Error'} variant={'body1'}>
                    {this.state.error}
                  </Typography>
                )}
                <Typography variant={'h6'}>
                  {this.state.word}&nbsp; [{this.state.latn}]
                  {this.state.audioUrl && (
                    <img
                      className={'audio-image'}
                      id="audioImg"
                      alt="audio"
                      onClick={() => this.playAudio()}
                      src={speaker}
                    />
                  )}
                </Typography>

                {this.state.newa && (
                  <span className={'transliteration-newa'}>
                    Nepal Lipi:{' '}
                    <span className={'NewaNano'}>{this.state.newa}</span>
                  </span>
                )}
                <ol>

                  {this.state.meanings &&
                    this.state.meanings.map((item: IWordMeaning, key: any) => {
                      return key < 2 ? this.renderItem(item, key) : null;
                    })}

                </ol>
                <Link color="inherit" className={this.props.highlighted ? "text-white" : ""}
                  variant={this.props.highlighted ? "h6" : "body1"} href={"/dictionary/nb/" + this.state.word}>more...</Link>
              </Fragment>
            </Box>
            <div></div>
          </Box>
        </Grid>
      </Box>
    );
  }

  renderItem(item: any, key: any): any {
    let compWord = item.compword;
    if (item.prefsuf === 'S') {
      compWord = '-' + compWord;
    }
    if (item.prefsuf === 'P') {
      compWord += '-';
    }

    return (
      <li key={key}>
        {compWord && <p>{compWord}</p>}
        <i>({item.pos})</i> {item.meaning}
        {item.formerword && (
          <p className="App-reference">Former Word: {item.formerword} </p>
        )}
        {item.origin && <p className="App-reference">Origin: {item.origin} </p>}
        {item.reference && (
          <p className="App-reference"> Reference: {item.reference.short} </p>
        )}
        {item.more && <p className="App-reference">See also: more</p>}
      </li>
    );
  }
}
export default WordDayComponent;
