import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CheckIcon from "@material-ui/icons/Check";
import AppService from '../../service/app-service';
import withWidth from '@material-ui/core/withWidth';
import theme from '../../theme';

const styles = (theme: any) => ({
  card: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2
  },
  cardHightlighted: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    border: `3px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2)
    }
  },
  title: {
    color: theme.palette.primary.main
  }
});

function RandomWordCard(props:any) {
  const { highlighted, classes } = props;
  const [randomwords, setRandomWords] = useState([]);

  useEffect(() => {
    AppService.getRandomWords().then((response: any) => {
      setRandomWords(response.words);
    });
  }, [])

  return (<div className={highlighted ? classes.cardHightlighted : classes.card}>
    <Box mb={2}>
      <Typography
        variant={highlighted ? "h5" : "h6"}
        className={highlighted ? "text-white" : classes.title}
      >
        न्हूगु खँग्वः सीकादिसँ
        </Typography>
    </Box>
    <Box mb={2}>
      <Typography
        variant={highlighted ? "h6" : "h6"}
        className={highlighted ? "text-white" : ""}
      >
        Learn new words
        </Typography>
    </Box>
    {randomwords?.map((word: string, index: number) => (
      <Box display="flex" alignItems="center" mb={1} key={index}>
        <CheckIcon
          style={{
            color: highlighted
              ? theme.palette.common.white
              : theme.palette.primary.dark
          }}
        />
        <Box ml={1}>
          <Link color="inherit" className={highlighted ? "text-white" : ""}
            variant={highlighted ? "h6" : "body1"} href={"/dictionary/nb/" + word}>{word}</Link>
        </Box>
      </Box>
    ))}
  </div>
  );
}
export default withWidth()(withStyles(styles, { withTheme: true })(RandomWordCard));
