import React, { Fragment, useRef, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import General from '../general';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import convertToUnicode from '../../shared/functions/preetiToUnicode';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AppService from '../../service/app-service';
import ProgressButton from '../../shared/components/progress-button';
let Sanscript = require('@sanskrit-coders/sanscript/sanscript.js');

const styles = (theme: any) => ({
  cardWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 340,
    },
  },
  card: {
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius * 2,
    cursor: 'pointer',
  },
  gridContainer: {
    paddingTop: theme.spacing(3),
  },
  cardContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.shape.borderRadius * 2,
    cursor: 'pointer',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  specialChars: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: theme.spacing(.5),
    padding: '2px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginRight: '3px',
      padding: '2px'
    }
  },
  specialCharsContainer: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2.5px',
      paddingRight: '2.5px'
    }
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
});

function ToolComponent(props: any) {
  const { classes } = props;
  const [format, setFormat] = useState('Preeti');
  const [inputVal, setInputValue] = useState('');
  const [outputVal, setOutputValue] = useState('');
  const [nepalLipiVal, setText] = useState('');
  const [progress, setProgress] = useState(false);
  const specialChars: string[] = ['zwnj', 'ण', 'ॐ', 'ज्ञ', 'क्ष', 'ञ', 'ङ', 'ॠ', 'ऋ', 'र्‍', 'र्‍य']
  const textAreaLipiRef = useRef<HTMLInputElement>(null);

  const placeholder = format === 'Preeti' ? 'yg 6fOk ofgflb;F' : 'Please type here';

  useEffect(() => {
    props.selectTool();
  });

  const handleChange = (event: any) => {
    setFormat(event.target.value);
    setInputValue('');
    setOutputValue('');
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (format === "Preeti") {
      setOutputValue(convertToUnicode(event.target.value));
    } else {
      var tst = Sanscript.t(event.target.value, 'itrans', 'devanagari', { syncope: true });
      setOutputValue(tst);
    }
  };

  const onClick = (event: any) => {
    setInputValue(inputVal.concat(event.target.id === 'zwnj' ? '' : event.target.id));
    setOutputValue(outputVal.concat(event.target.id === 'zwnj' ? '' : event.target.id));
  };


  const handleLipiCopy = (event: any) => {
    textAreaLipiRef?.current?.select();
    document.execCommand('copy');
    event.target.focus();
  };

  const handleConvert = () => {
    let data = {
      'from': 'deva',
      'to': 'newa',
      'text': outputVal
    };
    setProgress(true);
    AppService.convertText(data).then((response: any) => {
      setText(response.body[0].newa);
      setProgress(false);
    })
  };

  const mainContainer = () => {
    const { width } = props;
    return (
      <Fragment>
        <Box pt={3} pr={3} pl={3} pb={2} className='row'>
          <Typography variant={isWidthUp("lg", width) ? 'h4' : 'h5'}>नेपाल लिपि हिइके ज्याभः (Nepal Lipi Converter)</Typography>
        </Box>
        <Box pt={3} pr={3} pl={3} pb={2} className='row'>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={5}
              lg={5}>
              <FormControl className={classes.formControl} id="conversionTool">
                <InputLabel>Select input type</InputLabel>
                <Select
                  labelId="format"
                  id="demo-simple-select"
                  value={format}
                  onChange={handleChange}
                >
                  <MenuItem value={'Preeti'}>Preeti (बकमान Keyboard)</MenuItem>
                  <MenuItem value={'Roman'}>Roman (ka क, kha ख etc.)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              lg={7}>
              <div className={classes.specialCharsContainer}>
                {specialChars.map((special: string, index: number) => (
                  <span id={special} key={index} className={classes.specialChars} onClick={onClick}> {special} </span>
                ))}
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box p={3} className='row'>
          <Grid container spacing={4}>
            <Grid
              className={classes.cardWrapper}
              item
              xs={12}
              sm={6}
              lg={6}>
              <TextField
                multiline
                className={classes.card}
                id='input'
                label={format}
                minRows={9}
                value={inputVal}
                placeholder={placeholder}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={format === "Preeti" ? { className: 'Preeti' } : {}}
                onChange={handleTextChange} />
            </Grid>
            <Grid
              className={classes.cardWrapper}
              item
              xs={12}
              sm={6}
              lg={6}>
              <TextField
                className={classes.card}
                multiline
                id='output'
                label={'Devanagari Unicode'}
                minRows={10}
                value={outputVal}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleTextChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={3} className='row'>
          <Button
            onClick={handleConvert}
            variant="contained"
            color="secondary"
          >
            Convert {progress && <ProgressButton />}
          </Button>
        </Box>
        <Box p={3} className='row'>
          <TextField
            inputRef={textAreaLipiRef}
            className={classes.card}
            multiline
            id="lipi"
            label={'Nepal Lipi'}
            minRows={10}
            value={nepalLipiVal}
            inputProps={{ className: 'NewaNano' }}
            variant="outlined"
          />
        </Box>
        <Box p={3} className='row'>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLipiCopy}
          >
            Copy Nepal Lipi
        </Button>
        </Box>
      </Fragment>
    );
  };

  return <General mainContent={mainContainer()} />;
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(ToolComponent)
);
