import React, { useState, useCallback } from 'react';
import Footer from './component/footer/footer';
import NavBar from './component/navigation/nav-bar';
import smoothScrollTop from './shared/functions/smoothScrollTop';
import Routing from './Routing';
import SearchBar from './component/search/search-bar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
AOS.init({ once: true });

function App() {
  const [selectedTab, setSelectedTab] = useState('');
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const handleMobileDrawerOpen = useCallback(() => {
    setIsMobileDrawerOpen(true);
  }, [setIsMobileDrawerOpen]);

  const handleMobileDrawerClose = useCallback(() => {
    setIsMobileDrawerOpen(false);
  }, [setIsMobileDrawerOpen]);

  const selectHome = useCallback(() => {
    smoothScrollTop();
    document.title = 'Online Nepalbhasa Dictionary and Learning Tools | nepalbhasa.org';
    setSelectedTab('Home');
  }, [setSelectedTab]);

  const selectQuiz = useCallback(() => {
    smoothScrollTop();
    document.title = 'Online Nepalbhasa Quiz, Learn Nepalbhasa  | nepalbhasa.org';
    setSelectedTab('Quiz');
  }, [setSelectedTab]);

  const selectNames = useCallback(() => {
    smoothScrollTop();
    document.title = 'Nepalbhasa Names, Newa Baby Names | nepalbhasa.org';
    setSelectedTab('Names');
  }, [setSelectedTab]);

  const selectProverbs = useCallback(() => {
    smoothScrollTop();
    document.title = 'Nepalbhasa Proverbs | nepalbhasa.org';
    setSelectedTab('Proverbs');
  }, [setSelectedTab]);

  const selectAboutUs = useCallback(() => {
    smoothScrollTop();
    document.title = 'About Us | nepalbhasa.org';
    setSelectedTab('About');
  }, [setSelectedTab]);

  const selectVideos = useCallback(() => {
    smoothScrollTop();
    document.title = 'Videos | nepalbhasa.org';
    setSelectedTab('Videos');
  }, [setSelectedTab]);

  const selectTool = useCallback(() => {
    smoothScrollTop();
    document.title = 'Nepalbhasa Conversion Tool | nepalbhasa.org';
    setSelectedTab('Tools');
  }, [setSelectedTab]);

  const selectPolicy = useCallback(() => {
    smoothScrollTop();
    document.title = 'Privacy Policy| nepalbhasa.org';
    setSelectedTab('Policy');
  }, [setSelectedTab]);

  const selectTerms = useCallback(() => {
    smoothScrollTop();
    document.title = 'Terms of Service | nepalbhasa.org';
    setSelectedTab('Terms');
  }, [setSelectedTab]);

  const selectMeanings = useCallback(() => {
    smoothScrollTop();
    document.title = 'Online Nepalbhasa Dictionary and Learning Tools | nepalbhasa.org';
    setSelectedTab('Home');
  }, [setSelectedTab]);

  const selectEnglishWords = useCallback(() => {
    smoothScrollTop();
    document.title = 'Browse English Words | nepalbhasa.org';
    setSelectedTab('EnglishWords');
  }, [setSelectedTab]);

  const location = useLocation();
  const searchUrl = location.pathname.split('/');
  const path = searchUrl[1];
  const word = searchUrl !== undefined && path.toLowerCase() === 'dictionary'? searchUrl[3] : '';
  const lang = searchUrl !== undefined ? searchUrl[2] : '';

  return (
    <div className="App">
      <NavBar
        selectedTab={selectedTab}
        mobileDrawerOpen={isMobileDrawerOpen}
        handleMobileDrawerOpen={handleMobileDrawerOpen}
        handleMobileDrawerClose={handleMobileDrawerClose}
      />
      <SearchBar word={word} lang={lang}/>
      <Routing
        selectHome={selectHome}
        selectProverbs={selectProverbs}
        selectNames={selectNames}
        selectMeanings={selectMeanings}
        selectQuiz={selectQuiz}
        selectAboutUs={selectAboutUs}
        selectVideos={selectVideos}
        selectPolicy={selectPolicy}
        selectTerms={selectTerms}
        selectTool={selectTool}
        selectEnglishWords = {selectEnglishWords}
      />
      <Footer />
    </div>
  );
}

export default App;
