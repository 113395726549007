import React, { useEffect, useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import General from '../general';
import QuizCard, { IQuizDetailProps } from './quiz-card';
import AppService from '../../service/app-service';
import BreadCrumb from '../../shared/components/breadcrumb';
import Box from '@material-ui/core/Box';

const styles = (theme: any) => ({
  cardWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 340,
    },
  },
  randomWordWrapper: {
    marginTop: theme.spacing(8),
  },
  gridContainer: {
    paddingTop: theme.spacing(3),
  }
});

export interface IQuizSubGroup {
  id?: string;
  header?: string;
  detail?: string;
}

export interface IQuizDifficulty {
  id?: string;
  header?: string;
  detail?: string;
  subGroups?: any;
}

function QuizComponent(props: any) {
  const { classes, history } = props;

  const [quizLevels, setQuizLevels] = useState<IQuizDifficulty[]>([]);
  const [quizSubLevel, setQuizSubLevel] = useState<IQuizSubGroup[]>([]);

  const search = props.location.search;
  const params = new URLSearchParams(search);
  const paramQuizLevel = params.get('level');

  useEffect(() => {
    props.selectQuiz();
    AppService.getQuizGroups().then((response: any) => {
      let formatQuiz: IQuizDifficulty[] = [];

      Object.keys(response).map((key: any) => {
        return formatQuiz = [
          ...formatQuiz,
          {
            id: response[key].level,
            header: response[key].level,
            detail: response[key].description,
            subGroups: response[key].quizes,
          },
        ];
      })

      setQuizLevels(formatQuiz);
    });
  }, [props]);

  useEffect(() => {
    if (paramQuizLevel) {
      const quizDetail = quizLevels.find(
        (level) => level.id === paramQuizLevel
      );

      const formatSubGroups = quizDetail?.subGroups.map((group: any) => ({
        id: group.id,
        header: group.title,
        detail: group.description,
      }));
      setQuizSubLevel(formatSubGroups);
    }
  }, [quizLevels, paramQuizLevel]);

  const handleCardClick = (data: IQuizDetailProps) => {
    if (!paramQuizLevel) {
      history.push(`/quiz?level=${data.id}`);
    } else {
      history.push(`/quiz/${paramQuizLevel}/${data.id}`);
    }
  };

  const mainContainer = () => {
    const { width } = props;
    return (
      <Fragment>
        {paramQuizLevel && (
          <Box pt={3} pr={3} pl={3} pb={2} className='row'>
            <BreadCrumb
              data={[
                { label: 'Quiz Levels', url: '/quiz' },
                { label: `Level: ${paramQuizLevel}` },
              ]}
            />
          </Box>
        )}
        <Box pt={3} pr={3} pl={3} pb={2} className='row'>
          <Typography variant={isWidthUp("lg", width) ? "h4" : "h5"}>न्ह्यसःलिसः कासा (Quiz)</Typography>
        </Box>
        <Box p={3} className='row'>
          {!paramQuizLevel ? (
            <Grid container spacing={4}>
              {(quizLevels || []).map((groupDetails: IQuizDetailProps) => (
                <Grid
                  key={groupDetails?.id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className={classes.cardWrapper}
                  data-aos="flip-up"
                >
                  <QuizCard
                    detail={groupDetails}
                    onCardClick={handleCardClick}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
              <Grid container spacing={4}>
                {(quizSubLevel || []).map((groupDetails: IQuizSubGroup) => (
                  <Grid
                    key={groupDetails?.id}
                    item
                    xs={12}
                    lg={4}
                    className={classes.cardWrapper}
                    data-aos="flip-up"
                  >
                    <QuizCard
                      detail={groupDetails}
                      onCardClick={handleCardClick}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
        </Box>
      </Fragment>
    );
  };

  return <General mainContent={mainContainer()} />;
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(QuizComponent)
);
