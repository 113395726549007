import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import classNames from 'classnames';
import RandomWordCard from '../home/random-word';
import General from '../general';
import AppService from '../../service/app-service';
import PaginationLink from '../../shared/components/pagination-link';
import IndexedList from '../../shared/components/indexed-list';

const styles = (theme: any) => ({
  randomWordWrapper: {
    marginTop: theme.spacing(8),
  },
  link: {
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  noDecoration: {
    textDecoration: 'none !important',
  },
  showFocus: {
    '&:focus span': {
      color: theme.palette.secondary.dark,
    },
  },
  title: {
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
  leftSection: {
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
});

export interface IWordsList{
  words: string[];
  page: number;
  letter: string;
  totalcount: number;
}

function EnglishWords(props: any) {
  const { classes, width } = props;
  const [wordList, setWordList] = useState<IWordsList>();
  const [totalCount, setTotalCount] = useState(60);
  const [indexLetter, setIndexLetter] = useState('A');
  const [showPagination, setShowPagination] = useState(false);
  const data = [...Array(26)].map((_, index) => String.fromCharCode(index + 65)); // Generates A to Z alphabet array
  const pageSize: number = 60;

  useEffect(() => {
    props.selectEnglishWords();
    const page = props.match.params.page === undefined ? 1: props.match.params.page;
    const letter = props.match.params.letter === undefined ? "A": props.match.params.letter;
    setIndexLetter(letter);
    AppService.getEnglishWords(page, pageSize, letter).then((response: any)=>{
      setWordList(response);
      setTotalCount(Math.ceil(response.totalcount/ pageSize))
      setShowPagination(response.totalcount > pageSize);
    })  
  }, [props])

  const onPaginationChange = (event: any, value: number): void => {
    props.selectEnglishWords();
  };

  const mainContainer = () => {
    return (<Fragment>
      <Box pt={3} pr={3} pl={3} pb={2} className='row'>
        <Typography variant={isWidthUp("lg", width) ? "h4" : "h5"}>अंग्रेजी खँग्वः (Browse English Words)</Typography>
      </Box>
      <IndexedList selectedLetter={indexLetter} data={data} route='english-words/en'/>
      <Box p={3} className='row'>
        <Grid container spacing={1}>
          {wordList?.words.map((word: any, index: number) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Link
                to={'/dictionary/en/' + word}
                className={classNames(
                classes.noDecoration,
                classes.showFocus
                )}
              >
              <Typography className={classes.title}>
                {word}
              </Typography>
            </Link>
          </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={3} className='row'>
        {showPagination && <PaginationLink
          routeUrl={`english-words/en/${indexLetter.toLowerCase()}`}
          count={totalCount}
          page={wordList?.page}
          onPaginationChange={onPaginationChange}
        />}
      </Box>
    </Fragment>);
  };
    
  const topContainer = () => {
    return (
      <div className={classes.randomWordWrapper}>
        <RandomWordCard highlighted={false} />
      </div>
    )};
    return (
      <General
        mainContent={mainContainer()}
        sideContentTop={topContainer()}
      />
    );
}

export default withWidth()(withStyles(styles, { withTheme: true })(EnglishWords));
