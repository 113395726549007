import React, { Fragment } from 'react';
import { IWordMeaning, ISearchResult } from '../../models/word-meaning';
import smoothScrollTop from '../../shared/functions/smoothScrollTop';
import General from '../general';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Link from '@material-ui/core/Link';
import { withStyles, Theme } from '@material-ui/core/styles';
import { decode } from 'html-entities';
import Tooltip from '@material-ui/core/Tooltip';
import WordService from '../../service/word-service';
import speaker from '../../images/speaker_on.jpg';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
export interface IResultState {
  searchResult: ISearchResult;
  open: boolean
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 290,
    fontSize: 11,
  },
}))(Tooltip);

const styles = (theme: any) => ({
  image: {
    width: '100%',
    height: 'auto'
  },
  title: {
    width: '100%'
  },
  disclaimer:{
    fontStyle: 'italic',
    color: '#454545'
  }
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
class ResultComponent extends React.Component<any, IResultState> {
  constructor(props: any) {
    super(props);
    let word =
      this.props.match.params.word === undefined
        ? ''
        : this.props.match.params.word;
    this.state = {
      searchResult: {
        meanings: [],
        error: '',
        word: word,
        audioUrl: '',
        imageUrl: ''
      },
      open: false
    };
  }

  componentDidMount() {
    smoothScrollTop();
    let word =
      this.props.match.params.word === undefined
        ? ''
        : this.props.match.params.word;
    if (word) {
      this.getMeanings(word);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    let prevWord = prevProps.match.params.word;
    let currentWord = this.props.match.params.word;
    if (prevWord !== currentWord) {
      this.getMeanings(currentWord);
    }
  }

  private getMeanings(word: string) {
    WordService.GetWordMeaning(word).then((response: ISearchResult) => {
      this.setState({
        searchResult: response,
      });
    });
  }

  private playAudio = (audioUrl: string | undefined): any => {
    const audio = new Audio(audioUrl);
    audio.play();
  };

  private handleOpen = (): any => {
    this.setState({
      open: true
    });
  };

  private handleClose = (): any => {
    this.setState({
      open: false
    });
  };

  renderItem(item: any, key: any): any {
    let compWord = '';

    if ((item.prefsuf === 'S') || (item.prefsuf === 'P')) {
      compWord = item.compword;
    }
    if (item.prefsuf === 'S') {
      compWord = '-' + compWord;
    }
    if (item.prefsuf === 'P') {
      compWord += '-';
    }

    return (
      <li key={key}>
        {compWord && <p>{compWord}</p>}
        <i>({item.pos})</i> {item.meaning}
        {item.formerword && (
          <p className="App-reference">Former Word: {item.formerword} </p>
        )}
        {item.origin && <p className="App-reference">Origin: {item.origin} </p>}
        {item.reference && (
          <p className="App-reference">
            Reference:&nbsp;
            <HtmlTooltip
              placement="right"
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: decode(item.reference.full),
                  }}
                />
              }
            >
              <span>{item.reference.short}</span>
            </HtmlTooltip>
          </p>
        )}
        <div>
          {item.relatedwords !== null && <span className="App-relatedwords">See Also:&nbsp;</span>}
          {item.relatedwords &&
            item.relatedwords.map((word: string, key: any) => {
              return (
                (key < item.relatedwords.length - 1) ? < Link
                  className="App-relatedwords"
                  href={'/dictionary/nb/' + word}
                >
                  {word},&nbsp;
                </Link> : < Link
                  className="App-relatedwords"
                  href={'/dictionary/nb/' + word}
                >
                  {word}
                </Link>
              );
            })
          }</div>
      </li >
    );
  }

  mainContainer = () => {
    const { classes, width } = this.props;
    const result = this.state.searchResult;

    return (<Fragment>
      <Box p={3} className='row'>
        <Typography variant={isWidthUp("lg", width) ? "h4" : "h5"} className={classes.title}>
          {result.transliterations?.deva && (
            <span className={'transliteration-deva'}>
              {result.transliterations?.deva}{' '}
            </span>
          )}
          {result.transliterations?.latn && (
            <span className={'transliteration-latn'}>
              [{result.transliterations?.latn}]{' '}
            </span>
          )}
          {result.audioUrl && (
            <img
              className={'audio-image'}
              id="audioImg"
              alt="audio"
              onClick={() => this.playAudio(result.audioUrl)}
              src={speaker}
            />
          )}
        </Typography>
        <Typography variant={isWidthUp("lg", width) ? "h4" : "h5"}>
          {result.transliterations?.newa && (
            <p className={'transliteration-newa'}>
              Nepal Lipi:{' '}
              <span className={'NewaNano'}>
                {result.transliterations?.newa}
              </span>
            </p>
          )}
        </Typography>
      </Box>
      {result.meanings.length === 0 && result.error && (
        <Box p={3} className='row'>
          <Typography variant={'body1'} className='Error'>{result.error}</Typography>
        </Box>
      )}
      <Box justifyContent="space-between">
        <Grid container spacing={1}>
          <Grid item md={9} sm={9} xs={12}>
            <ol>
              {result.meanings &&
                result.meanings.map((item: IWordMeaning, key: any) => {
                  return this.renderItem(item, key);
                })}
            </ol>
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            {result.imageUrl && (
              <div style={{ position: 'relative' }}>
                <img alt={result.word} className={classes.image} src={result.imageUrl} />
                <div style={{ position: 'absolute', top: '0px' }}>
                  <Hidden smDown>
                    <IconButton style={{ background: 'white' }} aria-label="zoom" onClick={this.handleOpen}>
                      <ZoomInIcon />
                    </IconButton>
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby={result.transliterations?.deva}
                      aria-describedby={result.transliterations?.deva}
                    >
                      <DialogTitle onClose={this.handleClose}>
                        {result.transliterations?.deva}{' '}
                      </DialogTitle>
                      <DialogContent><img alt={result.transliterations?.deva} className={classes.image} src={result.magnifiedImageUrl} />
                      </DialogContent>
                    </Dialog>
                  </Hidden>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Box >
      {result.imageUrl &&  
        <Box p={3} className='row'>
          <Typography variant="caption" className={classes.disclaimer}>
            Disclaimer: The image shown here is for the respective Nepalbhasa word, which might have 
            multiple meanings. The image relates to one of its meaning.
          </Typography>
        </Box>}
    </Fragment >
    );
  };

  render() {
    return <General mainContent={this.mainContainer()} />;
  }
}
export default withWidth()(
  withStyles(styles, { withTheme: true })(ResultComponent)
);
