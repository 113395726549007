import React from "react";
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import calculateSpacing from "../../shared/functions/calculateSpacing";
import CountUp from 'react-countup';

const styles = (theme: any) => ({
    containerFix: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        overflow: "hidden",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    counter: {
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.h4.fontSize,
        },
    },
    counterHeader: {
        '&::after': {
            content: `''`,
            position: 'absolute',
            left: 'calc(50% - 50px);',
            width: '100px',
            border: `2px solid ${theme.palette.primary.main}`,
            bottom: '5px'
        }
    },
    counterTitle: {
        color: theme.palette.secondary.main,
    }
});

function CounterSection(props: any) {
    const { width, classes } = props;
    return (
        <div className={classNames(classes.containerFix, "container")}>
            <Grid
                container
                spacing={calculateSpacing(width)}
                className={classes.gridContainer}
                style={{ textAlign: 'center' }}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12} style={{ position: 'relative' }}>
                    <Typography className={classes.counterHeader}
                        variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                        WHAT WE HAVE NOW
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <CountUp delay={1} start={20000} end={41231} duration={5} className={classes.counter} />
                            <Typography
                                className={classes.counterTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                Words
                            </Typography>
                        </Grid>
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <CountUp delay={1} start={20000} end={48451} duration={5} className={classes.counter} />
                            <Typography className={classes.counterTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                Meanings
                            </Typography>
                        </Grid>
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <CountUp delay={1} start={100} end={1223} duration={5} className={classes.counter} />
                            <Typography className={classes.counterTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                Proverbs
                            </Typography>
                        </Grid>
                        <Grid item xs={6}
                            sm={3}
                            lg={3}>
                            <CountUp delay={1} start={100} end={1050} duration={5} className={classes.counter} />
                            <Typography className={classes.counterTitle}
                                variant={isWidthUp("lg", width) ? "h5" : "h6"}>
                                Baby Names
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}

export default withWidth()(withStyles(styles, { withTheme: true })(CounterSection));
