import React , { useEffect} from 'react';
import PropTypes from 'prop-types';
import { withWidth, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import 'react-multi-carousel/lib/styles.css';

import General from '../general';

const styles = (theme: any) => ({
  container: {
    alignItems: 'center'
  },
  title: {
    color: theme.palette.primary.main,
  },
  policyContainer: {
    marginTop: theme.spacing(3),
  },
});

function PolicyPage(props: any) {
  useEffect(() => {
    props.selectPolicy();
  });

  const mainContainer = (props: any) => {
    const { classes } = props;
    return (
      <div className={classes.container}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          className={classes.title}
        >
          PRIVACY POLICY
        </Typography>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
        >
        Last Updated on: January 22nd, 2024
        </Typography>
        <div className={classes.policyContainer}>
          <p>
            At Nepalbhasa.org, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines the types of information we collect, how we use and safeguard that information, and your rights and choices regarding your personal data when using our online Nepalbhasa dictionary and learning tools. By accessing and using our website, you consent to the practices described in this Privacy Policy.
          </p>
          <p>
            <b>Information Collection and Usage:</b><br/>
            1.1. Personal Information: We do not collect any personal information from users. You can browse our website and utilize our services anonymously without providing any personally identifiable information. No account creation or login is required.<br/>
            1.2. Non-Personal Information: We may collect non-personal information automatically when you visit our website, such as your IP address, browser type, operating system, and the pages you access. This information is used to analyze trends, administer the site, and improve our services.<br/>
            <br/>
            <b> Cookies and Similar Technologies:</b><br/>
            2.1. Cookies: We may use cookies to enhance your browsing experience and provide personalized content. Cookies are small text files stored on your device's browser that enable us to recognize you and remember certain information, such as your preferences. You have the option to disable cookies through your browser settings; however, this may affect the functionality of our website.<br/>
            2.2. Third-Party Analytics: We may use third-party analytics tools to collect and analyze non-personal information about your usage of our website. These tools use cookies and similar technologies to gather information that helps us improve our services and understand user preferences.<br/>
            <br/>
            <b>Data Security:</b><br/>
            We implement appropriate technical and organizational measures to protect your information from unauthorized access, disclosure, alteration, or destruction. Despite our best efforts, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.<br/>
            <br/>
            <b>External Links:</b><br/>
            Our website may contain links to external sites that are not operated by us. Please note that we have no control over the content or practices of these sites and are not responsible for their privacy policies. We encourage you to review the privacy policies of any external websites you visit.<br/>
            <br/>
            <b>Children's Privacy:</b><br/>
            Our website is safe for individuals of all ages. We do not knowingly collect or solicit personal information from anyone, including children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove that information from our servers.<br/>
            <br/>
            <b>Ownership and Copyright:</b><br/>
            Nepalbhasa.org is owned and maintained by Dabuli, a nonprofit organization. Dabuli holds the copyright to all content on our website, including text, images, graphics, logos, and software. All rights reserved.<br/>
            <br/>
            <b>Everything is Free to Use:</b><br/>
            All features and services provided on our website are completely free to use. There are no charges or fees associated with accessing or utilizing our Nepalbhasa dictionary, learning tools, or any other resources.<br/>
            <br/>
            <b>Updates to the Privacy Policy:</b><br/>
            We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date at the top will reflect the date of revision. We encourage you to review this Privacy Policy periodically for any updates.<br/>
            <br/>
            If you have any questions or concerns about our Privacy Policy or your personal information, please contact us at info (@) dabuli.org.
          </p>
        </div>
      </div>
    );
  };

  return <General mainContent={mainContainer(props)} />;
}

PolicyPage.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(PolicyPage));
