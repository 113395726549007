import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';

const styles = (theme: any) => ({
  wrapper: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    }
  },
  container: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
});

interface IGeneralProps {
  mainContent: object;
  sideContentTop?: object;
  sideContentBottom?: object;
  classes: any;
}

function General(props: IGeneralProps) {
  const { classes, mainContent, sideContentTop, sideContentBottom } = props;
  const hasSideContents = sideContentTop || sideContentBottom;
  return (
    <div className={classes.wrapper}>
      <div className={classNames('container-fluid', classes.container)}>
        <div
          style={{
            flex: 1,
            minHeight: 'calc(100vh - 689px)',
          }}
        >
          <Grid container spacing={3}>
            <Grid item md={hasSideContents ? 9 : 12} xs={12}>
              <div className={classes.paperDiv} style={{ minHeight: '50vh' }}>
                {mainContent}
              </div>
            </Grid>
            {hasSideContents && (
              <Grid item md={3} xs={12}>
                <Grid container spacing={3}>
                  {sideContentTop && (
                    <Grid item xs={12}>
                      <div
                        className={classes.paperDiv}
                        style={{ minHeight: '25vh' }}
                      >
                        {sideContentTop}
                      </div>
                    </Grid>
                  )}

                  {sideContentBottom && (
                    <Grid item xs={12}>
                      <div
                        className={classes.paperDiv}
                        style={{ minHeight: '25vh' }}
                      >
                        {sideContentBottom}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default withWidth()(withStyles(styles, { withTheme: true })(General));
