import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import RandomWordCard from '../home/random-word';
import General from '../general';
import { INamesResult, IName } from '../../models/word-meaning';
import AppService from '../../service/app-service';
import PaginationLink from '../../shared/components/pagination-link';
import IndexedList from '../../shared/components/indexed-list';

const styles = (theme: any) => ({
  randomWordWrapper: {
    marginTop: theme.spacing(8),
  },
  link: {
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  noDecoration: {
    textDecoration: 'none !important',
  },
  showFocus: {
    '&:focus span': {
      color: theme.palette.secondary.dark,
    },
  },
  title: {
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
  leftSection: {
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(2),
  }
});

export interface IWordsList{
  names: IName[];
  page: number;
  letter: string;
  totalcount: number;
}

function Names(props: any) {
  const { classes, width } = props;
  const [wordList, setWordList] = useState<INamesResult>();
  const [totalCount, setTotalCount] = useState(60);
  const [indexLetter, setIndexLetter] = useState('अ');
  const [showPagination, setShowPagination] = useState(false);
  const data = ['अ', 'आ', 'इ', 'ई','उ','ओ','क', 'ख', 'ग', 'घ', 'च', 'छ', 'ज', 'त', 'थ', 'द', 'ध', 'न', 'प', 'फ', 'ब', 'भ', 'म', 'य', 'र', 'ल', 'व', 'स', 'ह'];
  const pageSize: number = 60;

  useEffect(() => {
    props.selectNames();
    const page = props.match.params.page === undefined ? 1: props.match.params.page;
    const letter = props.match.params.letter === undefined ? 'अ': props.match.params.letter;
    setIndexLetter(letter);
    AppService.getNames(page, pageSize, letter).then((response: INamesResult)=>{
      setWordList(response);
      setTotalCount(Math.ceil(response.totalnames/ pageSize))
      setShowPagination(response.totalnames > pageSize);
    })  
  }, [props])

  const onPaginationChange = (): void => {
    props.selectNames();
  };

  const mainContainer = () => {
    return (<Fragment>
      <Box pt={3} pr={3} pl={3} pb={2} className='row'>
        <Typography variant={isWidthUp("lg", width) ? "h4" : "h5"}>नेवाः नां (Newah Names)</Typography>
      </Box>
      <IndexedList selectedLetter={indexLetter} data={data} route='names/nb'/>
      <Box p={3} className='row'>
        <Grid container spacing={1}>
          {wordList?.names.map((word: any, index: number) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Link
                to={'/dictionary/nb/' + word.deva}
                className={classNames(
                classes.noDecoration,
                classes.showFocus
                )}
              >
              <Typography className={classes.title}>
                {word.deva}
              </Typography>
            </Link>
          </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={3} className='row'>
        {showPagination && <PaginationLink
          routeUrl={`names/nb/${indexLetter.toLowerCase()}`}
          count={totalCount}
          page={wordList?.page}
          onPaginationChange={onPaginationChange}
        />}
      </Box>
    </Fragment>);
  };
    
  const topContainer = () => {
    return (
      <div className={classes.randomWordWrapper}>
        <RandomWordCard highlighted={false} />
      </div>
    )};
    return (
      <General
        mainContent={mainContainer()}
        sideContentTop={topContainer()}
      />
    );
}

export default withWidth()(withStyles(styles, { withTheme: true })(Names));
