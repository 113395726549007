import React from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import General from '../general';

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5rem',
    flexDirection: 'column' as 'column',
  },
  title: {
    letterSpacing: '.7rem',
    textIndent: '.7rem',
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.only('xs')]: {
      fontSize: 28,
    },
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
});

function NotFoundPage(props: any) {
  const mainContainer = (props: any) => {
    const { classes } = props;

    return (
      <div className={classes.container}>
        <Typography variant="h2" align="center" color="inherit" gutterBottom>
          ¯\_(ツ)_/¯
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color="inherit"
          gutterBottom
          className={classes.title}
        >
          Page Not Found
        </Typography>
        <Typography className={classes.text}>
          Looks like you've followed a broken link or entered a URL that doesn't
          exist on this site.
        </Typography>
        <Button href="/" className={classes.button}>
          ← Back to our site
        </Button>
      </div>
    );
  };

  return <General mainContent={mainContainer(props)} />;
}

NotFoundPage.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(
  withStyles(styles, { withTheme: true })(NotFoundPage)
);
