import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom';
import General from '../general';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WordService from '../../service/word-service';
import smoothScrollTop from '../../shared/functions/smoothScrollTop';
import { ISearchResultInEnglish, IWordMeaningFromEnglish } from '../../models/word-meaning';
import speaker from '../../images/speaker_on.jpg';
import Tooltip from '@material-ui/core/Tooltip';
import { decode } from 'html-entities';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton/IconButton';

const styles = (theme: any) => ({
  image: {
    width: '100%',
    height: 'auto'
  },
  title: {
    width: '100%'
  },
  meanings:{
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('m')]: {
      paddingBottom: theme.spacing(1),
    }
  },
  disclaimer:{
    fontStyle: 'italic',
    color: '#454545'
  }
});

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 290,
    fontSize: 11,
  },
}))(Tooltip);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function SearchResultEnglish(props: any) {
  const routeParams: { word: string } = useParams();
  const { word: searchedWord } = routeParams;
  const [open, setOpen] = React.useState<boolean>(false);
  const [showDisclaimer, setShowDisclaimer] = React.useState<boolean>(false);
  const [imageUrl, setImageUrl] = React.useState<string|undefined>('');
  const [title, setTitle] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<ISearchResultInEnglish>()

  const playAudio = (audioUrl: string | undefined): any => {
    const audio = new Audio(audioUrl);
    audio.play();
  };

  const handleOpen = (imageUrl: string|undefined, title: string):any =>{
    setTitle(title);
    setImageUrl(imageUrl);
    setOpen(true);
  }

  React.useEffect(() => {
    if (!searchedWord) {
      return
    }
    smoothScrollTop();
    WordService.GetWordMeaningFromEnglish(searchedWord).then((response: ISearchResultInEnglish) => {
      setSearchResult(response);
      const anyImage = response.meanings.some(x => x.imageUrl !== '')
      setShowDisclaimer(anyImage);
    });

  }, [props, searchedWord])

  const mainContainer = () => {
    const { classes, width } = props;
    return (<Fragment>
      <Box p={3} className='row'>
        <Typography variant="h4" className={classes.title}>
          {searchedWord}
        </Typography>
      </Box>
      {searchResult?.meanings.length === 0 && searchResult.errors && (
        <Box p={3} className='row'>
          <Typography className={'Error'} variant={'body1'}>{searchResult.errors}</Typography>
        </Box>
      )}
      {searchResult?.meanings.map((result: IWordMeaningFromEnglish, index: number) => (
        <div className={classes.meanings}>
        <Grid container spacing={1}>
          <Grid item md={10} sm={9} xs={12}>
            <Typography className={classes.title} variant={isWidthUp("lg", width) ?  "h5" : "h6"} >
              <span> {index +1}. </span>
              {result.pos && (
                <i>({result.pos}) </i> 
              )}
              {result.transliterations?.deva && (
                <span>{result.transliterations?.deva}{' '}</span>
              )}
              {result.transliterations?.latn && (
                <span>[{result.transliterations?.latn}]{' '}</span>
              )}

              {result.dialect !== 'kathmandu' && (
                <span><i> - {result.dialect && `${result.dialect.charAt(0).toUpperCase()}${result.dialect.slice(1)} dialect`}</i></span>
              )}

              {result.audioUrl && (
                <img
                  className={'audio-image'}
                  id="audioImg"
                  alt="audio"
                  onClick={() => playAudio(result.audioUrl)}
                  src={speaker}
                />
              )}
            </Typography>
            <Typography variant={isWidthUp("lg", width) ?  "h5" : "h6"} className={classes.title}>
              {result.transliterations?.newa && (
                <p className={'transliteration-newa'}>
                  Nepal Lipi:{' '}
                  <span className={'NewaNano'}>
                    {result.transliterations?.newa}
                  </span>
                </p>
              )}
            </Typography>
            <Typography variant={isWidthUp("lg", width) ?  "h5" : "h6"} className={classes.title}>
              {result.reference && (
              <p className="App-reference"> Reference:&nbsp;
                <HtmlTooltip
                  placement="right"
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decode(result.reference.full),
                      }}
                      />
                    }>
                  <span>{result.reference.short}</span>
                </HtmlTooltip>
              </p>
              )}
            </Typography>
            <Typography className={classes.title}>
              {result.meaning_en}
              {result.meaning_np && (
                <span>. Nepali: {result.meaning_np}</span>
              )}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            {result.imageUrl && (
              <div style={{ position: 'relative' }}>
                <img alt={result.transliterations?.deva} className={classes.image} src={result.imageUrl} />
                <div style={{ position: 'absolute', top: '0px' }}>
                  <Hidden smDown>
                    <IconButton style={{ background: 'white' }} aria-label="zoom" onClick={() =>handleOpen(result.magnifiedImageUrl, result.transliterations?.deva)}>
                    <ZoomInIcon />
                    </IconButton>
                  </Hidden>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        </div>
      ))}
      {showDisclaimer && <Typography variant="caption" className={classes.disclaimer}>
        Disclaimer: The image shown here is for the respective Nepalbhasa word, which might have 
        multiple meanings. The image relates to one of its meaning.
      </Typography>}
      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby={title}
        aria-describedby={title}
      >
        <DialogTitle onClose={()=>setOpen(false)}>
          {title}{' '}
        </DialogTitle>
        <DialogContent><img alt={title} className={classes.image} src={imageUrl} />
        </DialogContent>
      </Dialog>
    </Fragment >
    );
  }

  return <General mainContent={mainContainer()} />;
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(SearchResultEnglish)
);
