import React , { useEffect} from 'react';
import PropTypes from 'prop-types';
import { withWidth, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import 'react-multi-carousel/lib/styles.css';

import General from '../general';

const styles = (theme: any) => ({
  container: {
    alignItems: 'center'
  },
  title: {
    color: theme.palette.primary.main,
  },
  termsContainer: {
    marginTop: theme.spacing(3),
  },
});

function TermsPage(props: any) {
  useEffect(() => {
    props.selectTerms();
  });

  const mainContainer = (props: any) => {
    const { classes } = props;
    return (
      <div className={classes.container}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          className={classes.title}
        >
          TERMS OF SERVICE
        </Typography>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
        >
        Last Updated on: January 22nd, 2024
        </Typography>

        <div className={classes.termsContainer}>
          <p>
            Welcome to nepalbhasa.org! These Terms of Use outline the rules and guidelines for your use of our online Nepalbhasa dictionary and learning tools. By accessing or using our website, you agree to be bound by these Terms of Use. Please read them carefully.<br/>
            <br/>
            <b>Acceptance of Terms:</b><br/>
            1.1. By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use, as well as our Privacy Policy. If you do not agree with any of these terms, please refrain from using our website.<br/>
            <br/>
            <b>Intellectual Property Rights:</b><br/>
            2.1. All content, including text, images, graphics, logos, and software, provided on our website, is the property of Dabuli or its content suppliers and protected by applicable copyright and other intellectual property laws. You may not reproduce, distribute, modify, transmit, or use any content from our website without prior written consent.<br/>
            <br/>
            <b>Use of Services:</b><br/>
            3.1. Nepalbhasa.org provides various features and services, including but not limited to:<br/>
            <br/>
            Search Word Meaning: You can search for word meanings in Nepalbhasa and English translations.<br/>
            Browse Dictionary: Access and explore our comprehensive dictionary to browse word meanings.<br/>
            Word of the Day and Random Words: Discover new words by browsing our word of the day and random word features.<br/>
            Browse Baby Names: Explore a collection of baby names in Nepalbhasa.<br/>
            Browse Proverbs: Enjoy browsing a collection of proverbs in Nepalbhasa.<br/>
            Play Quiz: Engage in educational quizzes related to Nepalbhasa language and culture.<br/>

            <br/>
            3.2. Your use of these services is subject to the following conditions:<br/>
            <br/>
            You may use the services for personal, non-commercial purposes only.<br/>
            You may not use the services for any illegal, harmful, or unauthorized activities.<br/>
            You may not interfere with the proper functioning of our website or attempt to gain unauthorized access to our systems.<br/>
            <br/>

            <b>User Conduct:</b><br/>
            4.1. When using our website, you agree to abide by the following guidelines:<br/>
            <br/>
            Provide accurate and truthful information when using any interactive features or submitting user-generated content.<br/>
            Respect the rights of others and refrain from posting or transmitting any content that is offensive, defamatory, or infringes upon any intellectual property rights.<br/>
            Do not engage in any actions that may disrupt or interfere with the functioning of our website or compromise its security.<br/>
            <br/>

            <b>Disclaimer of Warranties:</b><br/>
            5.1. While we strive to provide accurate and reliable information, we do not guarantee the completeness, accuracy, or reliability of the content on our website. The use of our services is at your own risk.<br/>
            <br/>
            <b>Limitation of Liability:</b><br/>
            6.1. Nepalbhasa.org, Dabuli and its affiliates, employees, or agents shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services.<br/>
            <br/>
            <b>Modifications and Termination:</b><br/>
            7.1. We reserve the right to modify, suspend, or terminate any aspect of our website or services at any time without prior notice. We may also update these Terms of Use from time to time. Continued use of our website after any modifications indicates your acceptance of the revised terms.<br/>
            <br/>
            <b>Governing Law:</b><br/>
            8.1. These Terms of Use shall be governed by and construed in accordance with the laws of the Washington State (USA) and Nepal whichever is applicable, without regard to its conflict of law provisions.<br/>
            <br/>
            If you have any questions or concerns about these Terms of Use, please contact us at info (@) dabuli.org.<br/>
          </p>
        </div>
      </div>
    );
  };

  return <General mainContent={mainContainer(props)} />;
}

TermsPage.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(TermsPage));
