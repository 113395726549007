import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import useTheme from '@material-ui/core/styles/useTheme';
import AppService from '../../service/app-service';
import General from '../general';
import BreadCrumb from '../../shared/components/breadcrumb';
import Congratulation from './congratulation';

const styles = (theme: any) => ({
  wrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  quizContainer: {
    textAlign: 'center' as const,
  },
  header: {
    alignItems: 'center',
  },
  optionWrapper: {
    border: '1px solid black',
    borderRadius: 5,
    margin: 10,
    width: '100%',
  },
  randomWordWrapper: {
    marginTop: theme.spacing(8),
  },
});

type UserAnswer = {
  userAnswer: Array<string>;
};

type AnswerChosen = {
  [step: string]: UserAnswer;
};

type IQuiz = {
  id?: any;
  questionType?: any;
  question?: any;
  options?: any;
  correctAnswerIds?: any;
};

function Quiz(props: any) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [answerChosen, setAnswerChosen] = useState<AnswerChosen>({});
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [title, setTitle] = useState('');
  const [quizes, setQuizes] = useState<IQuiz[]>([]);
  const maxSteps = quizes.length;

  // get quiz

  useEffect(() => {
    const { match } = props;
    const id = match.params.quizId;

    AppService.getQuizQuestions(id).then((response: any) => {
      setQuizes(response.qnas);
      setTitle(response.title);
    });
  }, [props]);

  useEffect(() => {
    if (quizes.length && activeStep < maxSteps) {
      const selectedAnswers = answerChosen[activeStep]?.userAnswer;
      const { correctAnswerIds } = quizes[activeStep];

      // Check if the arrays are the same length
      if (selectedAnswers?.length !== correctAnswerIds?.length) {
        setDisableNextButton(true);
      } else {
        let disableButton = true;
        // Check if all selected items exist and are equal to correct answers
        for (let i = 0; i < selectedAnswers?.length; i++) {
          disableButton = false;
          if (!correctAnswerIds.includes(selectedAnswers[i])) {
            disableButton = true;
            break;
          }
        }
        setDisableNextButton(disableButton);
      }
    }
  }, [quizes, activeStep, maxSteps, answerChosen]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswerState(event.target.value);
  };

  const handleLevelClick = () => {
    props.history.push(`/quiz`);
  };

  const setAnswerState = (optionId: string) => {
    if (quizes[activeStep].questionType === 'SCQ') {
      // if scq, insert the option in state
      setAnswerChosen((prevState) => ({
        ...prevState,
        [activeStep]: {
          userAnswer: [optionId],
        },
      }));
    } else {
      let prevAnswers;
      let newAnswers: string[];

      // check which step to either insert in array or create array of userAnswers
      const checkStep = Object.keys(answerChosen).find(
        (ans) => +ans === activeStep
      );
      if (checkStep) prevAnswers = answerChosen[activeStep].userAnswer;
      if (prevAnswers?.some((prevAns) => prevAns === optionId)) {
        // if answer already exists, remove from unserAnswers array
        const index = prevAnswers.indexOf(optionId);
        if (index > -1) {
          prevAnswers.splice(index, 1);
          newAnswers = prevAnswers;
        }
      } else {
        newAnswers = prevAnswers ? [...prevAnswers, optionId] : [optionId];
      }
      setAnswerChosen((prevState) => ({
        ...prevState,
        [activeStep]: {
          userAnswer: newAnswers,
        },
      }));
    }
  };

  const setBorderStyle = (optionId: string) => {
    let setBorderStyle = {
      background: 'white',
      border: '2px solid black',
    };

    const prevAnswers = Object.keys(answerChosen).find(
      (ans) => +ans === activeStep
    );
    if (!prevAnswers) return setBorderStyle;

    if (answerChosen[prevAnswers].userAnswer.some((ans) => ans === optionId)) {
      if (
        quizes[activeStep].correctAnswerIds.some(
          (answerId: string) => answerId === optionId
        )
      ) {
        setBorderStyle = {
          background: '#00f34b',
          border: '2px solid #58ad36',
        };
      } else {
        setBorderStyle = {
          background: '#f1b5b5',
          border: '2px solid #ff0000',
        };
      }
    } else {
      setBorderStyle = {
        background: 'white',
        border: '2px solid black',
      };
    }

    return setBorderStyle;
  };

  const mainContainer = (props: any) => {
    const {
      classes,
      match: { params },
    } = props;

    return (
      <Fragment>
        <Box pt={3} pr={3} pl={3} pb={2} className="row">
          <BreadCrumb
            data={[
              { label: 'Quiz Levels', url: '/quiz' },
              {
                label: `Level: ${params.quizLevel}`,
                url: `/quiz?level=${params.quizLevel}`,
              },
              { label: `${title}` },
            ]}
          />
        </Box>
        <div className={classes.quizContainer}>
          {!quizes.length ? (
            <CircularProgress />
          ) : (
            <Fragment>
              <Box pt={3} pr={3} pl={3} pb={5} className="row">
                <Typography variant={'h4'} style={{ textAlign: 'center' }}>
                  {title}
                </Typography>
              </Box>

              {activeStep === maxSteps ? (
                <div
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                >
                  <Congratulation
                    onBackClick={handleBack}
                    onLevelClick={handleLevelClick}
                  />
                </div>
              ) : (
                <Fragment>
                  <Paper square elevation={0} className={classes.header}>
                    <div>
                      <Typography variant="h5">
                        {quizes[activeStep].question}
                      </Typography>
                    </div>

                    <div>
                      <FormControl component="fieldset" id='quiz'>
                        {quizes[activeStep].questionType === 'SCQ' ? (
                          <RadioGroup
                            aria-label="options"
                            name="options1"
                            value={
                              answerChosen[activeStep]?.userAnswer[0] || ''
                            }
                            onChange={handleChange}
                            style={{ flexDirection: 'initial' }}
                          >
                            {quizes[activeStep].options.map((option: any) => (
                              <div
                                key={option.id}
                                className={classes.optionWrapper}
                                style={setBorderStyle(option.id)}
                              >
                                <FormControlLabel
                                  value={option.id}
                                  control={<Radio />}
                                  label={option.option}
                                  key={option.id}
                                  style={{ width: '100%', margin: '5px' }}
                                />
                              </div>
                            ))}
                          </RadioGroup>
                        ) : (
                          <FormGroup style={{ flexDirection: 'initial' }}>
                            {quizes[activeStep].options.map((option: any) => (
                              <div
                                key={option.id}
                                className={classes.optionWrapper}
                                style={setBorderStyle(option.id)}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        answerChosen[activeStep]
                                          ? answerChosen[
                                              activeStep
                                            ].userAnswer.some(
                                              (answer) => answer === option.id
                                            )
                                          : false
                                      }
                                      onChange={handleChange}
                                      value={option.id}
                                      name={option.id}
                                    />
                                  }
                                  label={option.option}
                                  style={{ width: '100%', margin: '5px' }}
                                  name={option.id}
                                />
                              </div>
                            ))}
                          </FormGroup>
                        )}
                      </FormControl>
                    </div>
                  </Paper>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={disableNextButton}
                      >
                        {activeStep + 1 === maxSteps ? 'Continue' : 'Next'}
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                  />
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  };

  return <General mainContent={mainContainer(props)} />;
}

Quiz.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(Quiz));
