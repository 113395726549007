import React, { memo } from 'react';
import { Switch } from 'react-router-dom';
import Home from './component/home';
import Names from './component/names';
import Proverbs from './component/proverbs';
import ResultComponent from './component/search/search-result';
import EnglishResultComponent from './component/search/search-result-english';
import NepaliResultComponent from './component/search/search-result-nepali';
import PropsRoute from './shared/components/props-route';
import QuizComponent from './component/quiz';
import Quiz from './component/quiz/quiz';
import AboutPage from './component/about';
import Videos from './component/videos';
import NotFoundPage from './component/notfoundpage';
import ToolComponent from './component/tools';
import PolicyPage from './component/policy';
import TermsPage from './component/terms';
import EnglishWordList from './component/english-words';

function Routing(props: any) {
  const {
    selectProverbs,
    selectNames,
    selectHome,
    selectMeanings,
    selectQuiz,
    selectTool,
    selectAboutUs,
    selectVideos,
    selectPolicy,
    selectTerms,
    selectEnglishWords
  } = props;
  return (
    <Switch>
      <PropsRoute
        path={`/dictionary/nb/:word?`}
        component={ResultComponent}
        selectMeanings={selectMeanings}
      />
      <PropsRoute
        path={`/dictionary/en/:word?`}
        component={EnglishResultComponent}
        selectMeanings={selectMeanings}
      />
      <PropsRoute
        path={`/dictionary/np/:word?`}
        component={NepaliResultComponent}
        selectMeanings={selectMeanings}
      />
      <PropsRoute component={Home} selectHome={selectHome} path="/" exact />
      <PropsRoute
        path={`/names/nb/:letter?/:page?`}
        component={Names}
        selectNames={selectNames}
      />
      <PropsRoute
        path={`/proverbs/:page?`}
        component={Proverbs}
        selectProverbs={selectProverbs}
      />
      <PropsRoute
        path={'/quiz'}
        component={QuizComponent}
        selectQuiz={selectQuiz}
        exact
      />
       <PropsRoute
        path={'/nepal-lipi-converter'}
        component={ToolComponent}
        selectTool={selectTool}
        exact
      />
      <PropsRoute path="/quiz/:quizLevel/:quizId" component={Quiz} />
      <PropsRoute path="/about" component={AboutPage} selectAboutUs={selectAboutUs} exact />
      <PropsRoute path="/video" component={Videos} selectVideos={selectVideos} exact />
      <PropsRoute path="/privacy-policy" component={PolicyPage} selectPolicy={selectPolicy} exact />
      <PropsRoute path="/terms-of-service" component={TermsPage} selectTerms={selectTerms} exact />
      <PropsRoute path="/english-words/en/:letter?/:page?" component={EnglishWordList} selectEnglishWords={selectEnglishWords} exact />
      <PropsRoute component={NotFoundPage} />
    </Switch>
  );
}

export default memo(Routing);
