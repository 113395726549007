import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const styles = (theme: any) => ({
    root: {
        padding: '2px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '80%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    container: {
        display: 'flex',
        height: 100,
        justifyContent: 'center',
        marginTop: `${theme.spacing(9)}px !important`,
        [theme.breakpoints.down("md")]: {
            marginTop: `${theme.spacing(8)}px !important`
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: `${theme.spacing(8)}px !important`
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: `${theme.spacing(7)}px !important`
        }
    },
    languageSelector: {
        marginLeft: 10,
        paddingLeft: 10,
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 5,
        '&&:before': {
            borderBottom: "none"
        },
        '&&:after': {
            borderBottom: "none"
        },
        '&&:hover:not($disabled):after': {
           borderBottom: "none"
        },
        '&&:hover:not($disabled):before': {
           borderBottom: "none"
        },
    },
    icon: {
        fill: theme.palette.common.white,
    },
    selectedLang: {
        backgroundColor: 'rgba(253, 200, 69, .5) !important',
    },
    paper:{
        top:150,
    }
});

function SearchBar(props: any) {
    const languages = ['NB', 'EN', 'NP'];
    const [selectedLang, setSelectedLang] = useState(props.lang !== undefined ? props.lang.toUpperCase() : 'NB');
    const [word, setWord] = useState(props.word !== undefined ? props.word : '');

    useEffect(() => {
        setWord(props.word !== undefined ? props.word : '');
        setSelectedLang(props.lang !== undefined ? props.lang.toUpperCase() : 'NB');
    }, [props.word])

    const onWordChange = (event: any): void => {
        setWord(event.target.value);
    }

    const clearWord = (): void => {
        setWord('');
    }

    const handleChange = (event: any) => {
        setSelectedLang(event.target.value);
        setWord('');
    };

    return (<AppBar position="fixed" className={props.classes.container}>
        <Box display="flex" justifyContent="center" className="row">
            <Paper component="form" className={props.classes.root}>
                <Select
                    renderValue={(selected) => {
                    return selected;
                    }}
                    inputProps={{
                        root: props.classes.border,
                        classes: {
                            icon: props.classes.icon,
                        },
                    }}
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    className={props.classes.languageSelector}
                    onChange={handleChange}
                    value={selectedLang}
                >
                {languages.map((lang) => (
                    <MenuItem key={lang} value={lang} classes={{ selected: props.classes.selectedLang }}>
                        {lang === 'NB' ? 'Nepalbhasa - Nepalbhasa' : lang === 'NP' ? 'Nepali - Nepalbhasa' : lang === 'EN' ? 'English - Nepalbhasa' : ''}
                    </MenuItem>
                ))}
                </Select>
                <InputBase
                    onChange={onWordChange}
                    value={word}
                    className={props.classes.input}
                    placeholder="Search here"
                    inputProps={{ 'aria-label': 'search word' }}
                    required
                />
                {word !== '' && <IconButton onClick={clearWord} className={props.classes.iconButton} aria-label="clear">
                    <CloseIcon />
                </IconButton>}
                <Divider className={props.classes.divider} orientation="vertical" />
                <Link to={
                    `/dictionary/${selectedLang}/${word}`
                } style={{ textDecoration: 'none' }}>
                    <IconButton type="submit" color="primary" className={props.classes.iconButton} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Link>
            </Paper>
        </Box>
    </AppBar>
    );
}

export default withWidth()(withStyles(styles, { withTheme: true })(SearchBar));
