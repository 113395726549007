import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

export default function PaginationLink(props: any) {
    const { routeUrl, count, page, onPaginationChange } = props;
    return (
        <Pagination
            onChange={onPaginationChange}
            shape="rounded"
            page={Number(page)}
            count={count}
            renderItem={(item: any) => (
                <PaginationItem
                    component={Link}
                    to={`/${routeUrl}${item.page === 1 ? '' : `/${item.page}`}`}
                    {...item}
                />
            )}
        />
    );
}
