import React , { useEffect} from 'react';
import PropTypes from 'prop-types';
import { withWidth, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// TO DO : import all images in the directory rather than importing individually
import anjana from '../../images/anjana-maharjan.jpg'
import asmeet from '../../images/asmeet-malla.jpg'
import bipin from '../../images/bipin-kapali.jpg'
import chetana from '../../images/chetana-shakya.jpg'
import gyan from '../../images/gyan-maharjan.jpg'
import loojah from '../../images/loojah-ghaju.jpg'
import rajendra from '../../images/rajendra-manandhar.jpg'
import rajesh from '../../images/rajesh-tandukar.jpg'
import subhash from '../../images/subhash-ram-prajapati.jpg'
import sujan from '../../images/sujan-joshi.jpg'
import tej from '../../images/tej-maharjan.png'
import upasana from '../../images/upasana-shakya.jpeg'

import academy from '../../images/nepalbhasa-academy.png';
import lok from '../../images/lok-sahitya-parishad.jpg';
import kmc from '../../images/kathmandu-metropolitan-city-logo.jpg';
import kutah from '../../images/kutah-pikaka-logo.jpg';
import lipiGuthi from '../../images/nepal-lipi-guthi-logo.jpg';
import nepalGovt from '../../images/government-of-nepal-logo.jpg';


import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import General from '../general';

const styles = (theme: any) => ({
  container: {
    alignItems: 'center'
  },
  title: {
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: theme.spacing(3),
  },
  contributorContainer: {
    marginTop: theme.spacing(10),
    textAlign: 'center' as 'center',
    position: 'relative' as 'relative',
  },
  contributorCard: {
    marginTop: theme.spacing(3),
    textAlign: 'center' as 'center',
    display: 'block',
    [theme.breakpoints.only('xs')]: {
      marginRight: 58,
    },
  },
  contributorImage: {
    borderRadius: '50%',
    height: 150,
    width: 150,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative' as 'relative',
    margin: 'auto',
  },
  contributorDetailContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center' as 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1280 },
    items: 5,
  },
  tabletLarge: {
    breakpoint: { max: 1280, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 575 },
    items: 3,
  },
  bigMobileScreen: {
    breakpoint: { max: 575, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 1 },
    items: 1,
  },
};

const contributorsList = [
  {
    image: subhash,
    fullName: 'Subhash Ram Prajapati',
    contribution: 'Director/Lead Software Developer',
  },
  {
    image: asmeet,
    fullName: 'Asmeet Malla',
    contribution: 'Manager (Editing)',
  },
  {
    image: rajesh,
    fullName: 'Rajesh Tandukar',
    contribution: 'Manager (Data)',
  },
  {
    image: gyan,
    fullName: 'Gyan Maharjan',
    contribution: 'Manager (Editing)',
  },
  {
    image: chetana,
    fullName: 'Chetana Shakya',
    contribution: 'Software Developer',
  },
  {
    image: sujan,
    fullName: 'Sujan Krishna Joshi',
    contribution: 'Software Developer',
  },
  {
    image: tej,
    fullName: 'Tej Maharjan',
    contribution: 'Contributor',
  },
  {
    image: rajendra,
    fullName: 'Rajendra Manandhar',
    contribution: 'Contributor',
  },
  {
    image: bipin,
    fullName: 'Bipin Kapali',
    contribution: 'Contributor',
  },
  {
    image: anjana,
    fullName: 'Anjana Maharjan',
    contribution: 'Contributor',
  },
  {
    image: loojah,
    fullName: 'Loojah Ghaju',
    contribution: 'Contributor',
  },
  {
    image: upasana,
    fullName: 'Upasana Shakya',
    contribution: 'Contributor',
  },
];

const communityPartners = [
  {
    image: nepalGovt,
    name: 'Government of Nepal',
    address: 'Kathmandu, Nepal',
  },
  {
    image: kmc,
    name: 'Kathmandu Metropilitan City',
    address: 'Kathmandu, Nepal',
  },
  {
    image: lipiGuthi,
    name: 'Nepal Lipi Guthi',
    address: 'Kathmandu, Nepal',
  },
  {
    image: academy,
    name: 'Nepalbhasa Academy',
    address: 'Kirtipur, Nepal',
  },{
    image: lok,
    name: 'Lok Sahitya Parishad',
    address: 'Lalitpur, Nepal',
  },
  {
    image: kutah,
    name: 'Kutah Pikaka',
    address: 'Bhaktapur, Nepal',
  },

];

function AboutPage(props: any) {
  useEffect(() => {
    props.selectAboutUs();
  });

  const mainContainer = (props: any) => {
    const { classes } = props;

    return (
      <div className={classes.container}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          className={classes.title}
        >
          NEPALBHASA.ORG
        </Typography>
        <div className={classes.aboutUsDetail}>
          <Typography>
            Nepalbhasa.org is an online platform and community-led initiative established by <a href="http://www.dabuli.org" target='blank'>Dabuli </a>
            with the primary objective of preserving and promoting Nepalbhasa, also known as 
            the Newar language.  Through this platform, Nepalbhasa.org aims to provide tools, 
            documentation, and educational materials that contribute to the revitalization and 
            continued appreciation of the rich cultural heritage embodied within the Newar 
            language. By fostering community engagement and support, Nepalbhasa.org endeavors 
            to create a sustainable ecosystem for the preservation and promotion of 
            Nepalbhasa, thereby ensuring its enduring legacy for future generations.
          </Typography>
          <br />
          <Typography>
            Currently the site contains online dictionary with close to 30K words with
            their meaning, word origin, audio pronunciations, and images. It also contains
            proverbs, baby names and quiz. These have been collected from various
            Nepalbhasa Dictionaries published by individuals and institutions. We plan to
            induct many other various tools in future.
          </Typography>
          <br />
          <br />
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            className={classes.title}
          >
            DABULI
          </Typography>
          <Typography>
            Dabuli, as a US-based nonprofit organization, is focused on advancing a deeper understanding and 
            strengthening the rich music, arts, and cultural heritage of Nepal and the Himalayan region. 
            Its main objective is to build strong intercultural communities through various means, 
            including research, performance, celebration, advocacy, and collaboration.
            <br/>
            By conducting research, Dabuli likely aims to gather knowledge and insights into the music, arts, 
            and cultural traditions of Nepal and the Himalayan region. This research can help preserve and promote these valuable cultural assets.
            <br/>
            Through performances, Dabuli likely provides a platform for artists from Nepal and the Himalayan region 
            to showcase their talents and traditions. This can include music, dance, visual arts, and other forms of
            artistic expression.
            <br/>
            Celebrations organized by Dabuli can involve festivals, events, or gatherings that highlight and honor 
            the cultural heritage of Nepal and the Himalayan region. These celebrations provide opportunities for 
            communities to come together, share experiences, and foster cultural exchange.
            <br/>
            Advocacy efforts undertaken by Dabuli may involve raising awareness about the importance of preserving 
            and promoting the music, arts, and cultural heritage of Nepal and the Himalayan region. This can include 
            advocating for policies that support cultural preservation, encouraging educational programs, and 
            promoting intercultural understanding.
            <br/>
            Additionally, Dabuli seeks collaborative opportunities to further its mission. This can involve 
            partnerships with other organizations, institutions, or individuals who share a similar interest in 
            promoting and preserving cultural heritage. Collaborations can lead to innovative projects, 
            exchange programs, and joint initiatives that contribute to a deeper understanding of Nepal's and 
            the Himalayan region's cultural richness.
            <br />
            <br />
            Dabuli Nepal, a parallel organization of Dabuli, administers the project related tasks in Nepal
            with local community partners.
          </Typography>
        </div>

        <div className={classes.contributorContainer}>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            className={classes.title}
          >
            TEAM
          </Typography>
          <Carousel
            containerClass="container"
            responsive={responsive}
            autoPlay
            infinite
            autoPlaySpeed={5000}
          >
            {contributorsList.map((contributors) => {
              return (
                <div
                  className={classes.contributorCard}
                  key={contributors.fullName}
                >
                  {contributors.image ? (
                    <div
                      className={classes.contributorImage}
                      style={{
                        backgroundImage: `url(${contributors.image})`,
                      }}
                    ></div>
                  ) : (
                      <AccountCircleIcon style={{ fontSize: 150 }} />
                    )}
                  <div className={classes.contributorDetailContainer}>
                    <div className={classes.contributorName}>
                      <strong>
                        {contributors.fullName}
                      </strong>
                    </div>
                    <div className={classes.contribution}>
                      {contributors.contribution}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className={classes.contributorContainer}>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            className={classes.title}
          >
            COMMUNITY PARTNERS
          </Typography>
          <Carousel
            containerClass="container"
            responsive={responsive}
            autoPlay
            infinite
            autoPlaySpeed={5000}
          >
            {communityPartners.map((partners) => {
              return (
                <div
                  className={classes.contributorCard}
                  key={partners.name}
                >
                  {partners.image ? (
                    <div
                      className={classes.contributorImage}
                      style={{
                        backgroundImage: `url(${partners.image})`,
                      }}
                    ></div>
                  ) : (
                      <AccountCircleIcon style={{ fontSize: 150 }} />
                    )}
                  <div className={classes.contributorDetailContainer}>
                    <div className={classes.name}>
                      <strong>{partners.name}</strong>
                    </div>
                    <div className={classes.address}>
                      {partners.address}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>

      </div>
    );
  };

  return <General mainContent={mainContainer(props)} />;
}

AboutPage.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(AboutPage));
