import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import BookIcon from '@material-ui/icons/Book';
import SortByAlpha from '@material-ui/icons/SortByAlpha';
import NavigationDrawer from '../../shared/components/navigation-drawer';
import logo from '../../images/nepalbhasa-logo.png';
import DownloadAppPopup from '../downloadAppPopup/DownloadAppPopup';

const styles = (theme: any) => ({
  appBar: {
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButtonText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  brandText: {
    fontFamily: "'Baloo Bhaijaan', cursive",
    fontWeight: 400,
  },
  noDecoration: {
    textDecoration: 'none !important',
  },
});

function NavBar(props: any) {
  const {
    classes,
    handleMobileDrawerOpen,
    handleMobileDrawerClose,
    mobileDrawerOpen,
    selectedTab,
  } = props;
  const menuItems = [
    {
      link: '/',
      name: 'Home',
      icon: <HomeIcon className="text-white" />,
    },
    {
      link: '/english-words/en',
      name: 'Browse A-Z',
      icon: <SortByAlpha className="text-white" />,
    },
    {
      link: '/proverbs',
      name: 'Proverbs',
      icon: <BookIcon className="text-white" />,
    },
    {
      link: '/names/nb',
      name: 'Names',
      icon: <ChildFriendlyIcon className="text-white" />,
    },
    {
      link: '/quiz',
      name: 'Quiz',
      icon: <LocalLibraryIcon className="text-white" />,
    },
    {
      link: '/video',
      name: 'Video',
      icon: <OndemandVideoIcon className="text-white" />,
    },
    {
      link: '/nepal-lipi-converter',
      name: 'Tools',
      icon: <HowToRegIcon className="text-white" />,
    }
  ];
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            <img src={logo} alt="Nepalbhasa.org" />
          </div>
          <div>
            <Hidden mdUp>
              <IconButton
                className={classes.menuButton}
                onClick={handleMobileDrawerOpen}
                aria-label="Open Navigation"
              >
                <MenuIcon color="primary" />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              {menuItems.map((element) => {
                return (
                  <Link
                    key={element.name}
                    to={element.link}
                    className={classes.noDecoration}
                    onClick={handleMobileDrawerClose}
                  >
                    <Button
                      color="secondary"
                      size="large"
                      classes={{ text: classes.menuButtonText }}
                    >
                      {element.name}
                    </Button>
                  </Link>
                );
              })}
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        menuItems={menuItems}
        anchor="right"
        open={mobileDrawerOpen}
        selectedItem={selectedTab}
        onClose={handleMobileDrawerClose}
      />
      <DownloadAppPopup />
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(memo(NavBar));
