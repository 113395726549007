import configData from '../config.json';

export default class AppService {
  private static apiUrl: string = configData.API_URL;

  public static async searchPost(data: string): Promise<any> {
    try {
      const response = await fetch(this.apiUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async searchGet(word: string, lang = 'nb'): Promise<any> {
    try {
      // const response = await fetch(`${this.apiUrl}/search/${word}`);
      const response = await fetch(`${this.apiUrl}/${lang}/search/${word}`);
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async getRandomWords(): Promise<any> {
    try {
      const response = await fetch(`${this.apiUrl}/random/6`, {
        cache: 'no-cache',
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async getWordOfTheDay(): Promise<any> {
    try {
      const response = await fetch(`${this.apiUrl}/wordoftheday`);
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async getNames(
    pageNumber: number,
    perPage: number,
    letter?: string,
  ): Promise<any> {
    try {
      //names/countperpage/550/page/1
      let url = `${this.apiUrl}/names/countperpage/${perPage}/page/${pageNumber}`;
      if(letter !== null && typeof(letter) !== 'undefined'){
        url = `${this.apiUrl}/names/${letter}/countperpage/${perPage}/page/${pageNumber}`; 
      }
      const response = await fetch(url);
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }
  public static async getProverbs(
    pageNumber: number,
    perPage: number
  ): Promise<any> {
    try {
      //names/countperpage/550/page/1
      const response = await fetch(
        `${this.apiUrl}/proverbs/countperpage/${perPage}/page/${pageNumber}`
      );
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async getQuizGroups(): Promise<any> {
    try {
      const response = await fetch(`${this.apiUrl}/quiz`, {
        cache: 'no-cache',
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async getWordsOfWeek(): Promise<any> {
    try {
      const response = await fetch(`${this.apiUrl}/wordsoftheweek`, {
        cache: 'no-cache',
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }
  /**
   *
   * @param id Gets Quiz Questions
   */
  public static async getQuizQuestions(id: string): Promise<any> {
    try {
      const response = await fetch(`${this.apiUrl}/quiz/${id}`, {
        cache: 'no-cache',
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Get all and indexed english words
   * @param letter 
   * @param pageNumber 
   * @param perPage 
   * @returns 
   */
  public static async getEnglishWords(pageNumber: number,
    perPage: number,
    letter?: string
  ): Promise<any> {
    try {
      //list/a/countperpage/100/page/1
      let url = `${this.apiUrl}/list/a/countperpage/${perPage}/page/${pageNumber}`;
      if(letter !== null && typeof(letter) !== 'undefined'){
        url = `${this.apiUrl}/list/${letter?.toLowerCase()}/countperpage/${perPage}/page/${pageNumber}`
      }
      const response = await fetch(url);
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async convertText(data: any): Promise<any> {
    try{
      const response = await fetch(`${this.apiUrl}/lipiconverter`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json();
    } catch (error){
      console.log(error);
    } 
  }

  /**
   * Get today's date in different formats.
   * @returns 
   */
  public static async getTodaysDate(): Promise<any>{
    try {
      const response = await fetch(`${this.apiUrl}/todaysinfo`, {
        cache: 'no-cache',
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static async getVideos(): Promise<any> {
    try {
      const response = await fetch(`${this.apiUrl}/video`, {
        cache: 'no-cache',
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  public static jsonToArray(json: string): string[] {
    let arr: string[] = [];
    Object.keys(json).forEach(function (key) {
      arr.push(json);
    });
    return arr;
  }
}
